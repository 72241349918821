import {useEffect, useState} from 'react';

import fetchCompanies from '@services/fetch-companies';

import type {CompanyProps} from '../types';
import {phoneMask} from '@helpers/masks/phone';
import {cnpjMask} from '@helpers/masks/cnpj';

export default function useContact() {
  const [companyList, setCompanyList] = useState<CompanyProps[]>([]);

  useEffect(() => {
    async function getCompanies() {
      try {
        const request = await fetchCompanies();
        if (request && request.length > 0) {
          const parsedCompanies = request.map((company) => {
            return {
              id: company?.id,
              name: company?.nome,
              phone: phoneMask(company?.telefone),
              address: company?.cidade,
              cnpj: cnpjMask(company?.cnpj),
              creci: company?.creci,
            };
          });

          setCompanyList(parsedCompanies);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getCompanies();
  }, []);

  return {
    companyList,
  };
}
