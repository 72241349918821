import {Container} from 'reactstrap';

type Props = {
  hasSearch?: boolean;
};

const Navbar = ({hasSearch}: Props) => {
  if (hasSearch) {
    return (
      <>
        <header id="header" className="fixed-top">
          <Container className="d-flex align-items-center justify-content-between">
            <div>
              <a href="/" role="button">
                <img src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/mybroker-white.png?size=small" />
              </a>
            </div>

            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>
                  <a
                    href="https://locareweb.com/"
                    role="button"
                    target="_blank">
                    Login
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </Container>
        </header>
      </>
    );
  }

  return (
    <>
      <header id="header" className="fixed-top">
        <Container className="d-flex align-items-center justify-content-between">
          <div>
            <a href="/" role="button">
              <img src="https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/mybroker-white.png?size=small" />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a href="/">Inicio</a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  My Broker
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Serviços
                </a>
              </li>
              <li>
                <a href="/busca-rapida" role="button">
                  Pesquisar
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#personal">
                  Trabalhe Conosco
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contato
                </a>
              </li>
              <li>
                <a href="https://locareweb.com/" role="button" target="_blank">
                  Login
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </Container>
      </header>
    </>
  );
};

export default Navbar;
