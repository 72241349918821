/* eslint-disable */
import React from 'react';
import {notification} from 'antd';
import {Icon} from 'semantic-ui-react';

function Notify(type, title, message) {
  let icone = null;
  let backColor = null;

  switch (type) {
    case 'error':
      icone = <Icon color="red" name="times circle" size="large" />;
      backColor = '#F42029';
      break;
    case 'success':
      icone = <Icon color="green" name="check circle" size="large" />;
      backColor = '#2eb82e';
      break;
    case 'warning':
      icone = <Icon color="grey" name="check circle" size="large" />;
      backColor = '#F0F0F0';
      break;
    default:
      icone = <Icon color="orange" name="exclamation triangle" size="large" />;
      backColor = '#3192D1';
  }

  notification[type]({
    message: title ? title : 'Atenção',
    description: message,
    icon: icone,
    duration: 3,
  });
}

export default Notify;
