import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {Skeleton} from 'antd';

type SkeletonProps = {
  cardShadowless?: any;
};

const SkeletonSync = ({cardShadowless}: SkeletonProps): JSX.Element => {
  return (
    <Card className={cardShadowless ? 'shadow-none p-0 mb-0' : 'card-custom'}>
      <CardBody>
        <Skeleton paragraph={{rows: 2}} />
      </CardBody>
    </Card>
  );
};

export default SkeletonSync;
