import useContact from "@components/Contact/hooks/use-contact";

/* eslint-disable @next/next/no-img-element */
function Footer() {
  const { companyList } = useContact();
  var creci = 'CJ 29886';
  var cnpj = '21.156.749/0001-00';

  if (process.env.NEXT_PUBLIC_IS_MATRIZ !== 'true') {
    creci = companyList[0]?.creci || creci;
    cnpj = companyList[0]?.cnpj || cnpj;
  }

  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="copyright">
          &copy; Copyright{' '}
          <strong>
            <span>My Broker Imobiliária Ltda | CRECI {creci} | CNPJ {cnpj}</span>
          </strong>
        </div>
        <div className="credits">
          Developed by{' '}
          <a href="https://redelatitude.com.br/">
            <strong>
              <span> Latitude Tech</span>
            </strong>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
